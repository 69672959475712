//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { getStrapiMedia } from "../utils/medias";

export default {
  fetchOnServer: false,
  data: () => ({
    global: {navigation :[]},
    categories: []
  }),


  methods: {
    getStrapiMedia,
    handleScroll() {
      let header = document.querySelector(".site-header");
      let language = document.querySelector("#google_translate_element");

      if (window.scrollY > 100) {
        header.classList.add("is-fixed");
        language.classList.add("is-fixed-lang");
      } else {
        header.classList.remove("is-fixed");
        language.classList.remove("is-fixed-lang");
      }
    },
  },
  beforeMount() {
    window.addEventListener("scroll", this.handleScroll);
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.handleScroll);
  },

  //Fetching Global Data
  async fetch() {
    try {

     this.$strapi.graphql({
        query: `query{
                 categories {
                      id
                      slug
                      name
                      SortOrder
                      categoryImage {
                        url
                      }
                      image {
                        url
                      }
                    }
                }`
      }).then((res)=>{
      this.categories = res.categories
          .map((item) => item)
          .sort((a, b) => a.SortOrder - b.SortOrder);

        this.$store.commit('category/add', this.categories)

      });

    this.$strapi.find("global").then((res)=>{
        this.global = res
      });


    } catch (error) {
      console.error(error);
    }
  },
};
